const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec"
];
const DateService = {
  canShowMessage() {
    const msg1 = "Eid Mubarak! We";
    const msg2 = "closed till Eid Day 3 i.e. 23rd July, 2021 (Friday).";
    const date = new Date();
    const data = {
      month: months[date.getMonth()],
      date: date.getDate(),
      hour: date.getHours()
    };
    const dateString = `${data.date}-${data.month}`;
    if (["21-jul", "22-jul", "23-jul"].includes(dateString)) {
      return `${msg1}'re ${msg2}`;
    }
    if (dateString === "20-jul" && data.hour >= 17) {
      return `${msg1} will be ${msg2}`;
    }
    return "";
  },
  canPlaceOrder() {
    const date = new Date();
    const data = {
      month: months[date.getMonth()],
      date: date.getDate(),
      hour: date.getHours()
    };
    const dateString = `${data.date}-${data.month}`;
    if (
      ["21-jul", "22-jul", "23-jul"].includes(dateString) ||
      (dateString === "24-jul" && data.hour < 9)
    ) {
      return false;
    }
    return true;
  }
};
export default DateService;
