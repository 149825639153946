<template>
  <div class="place-order" :class="'place-order--' + placeNewOrder">
    <!-- <div class="login__form__errors login__form__errors--show">
        {{ "Requesting..." }}
      </div> -->

    <template v-if="processing">
      <baskyt-progress>
        <template v-slot:text>
          Placing order
        </template>
      </baskyt-progress>
    </template>
    <template v-else-if="placeNewOrder === 0" && !processing>
      <template v-if="nBranches !== null">
        <div class="container mb-4 pb-3">
          <div class="row">
            <div class="col-12"><h4>Pick up from</h4></div>
            <div class="col-12">
              <b-form-select
                :disabled="branchesDisabled"
                v-model="selectedBranch"
                :options="branches"
                value-field="id"
                html-field="html"
              ></b-form-select>
            </div>
          </div>
        </div>
      </template>
      <a
        href="javascript:void(0);"
        class="place-order__link"
        @click="placeOrder()"
      >
        <img
          src="@/assets/static-assets/app-icons/white-baskyt-red.png"
          alt="Place Order Baskyt Image"
        />
      </a>
      <div class="round-loader" v-if="nBranches === null">
        <div class="round-loader__content round-loader__content--1"></div>
        <div class="round-loader__content round-loader__content--2"></div>
      </div>
      <span class="place-order__text" v-if="nBranches === null">
        Searching for branches
      </span>
      <span class="place-order__text" v-if="nBranches !== null">
        Push the button to place a delivery order
      </span>
    </template>
    <template v-else-if="placeNewOrder === 1" && !processing>
      <div class="place-order__form slide-pop-down-enter-active">
        <h3 class="mr-auto pt-3">
          <a href="javascript:void(0)" @click="backToStep1"
            ><i class="fa fa-chevron-left text-danger"></i
          ></a>
        </h3>
        <h3 class="place-order__form__heading mt-3">Order Details</h3>
        <b-row>
          <b-col cols="12">
            <p class="text-danger">{{ orderError }}</p>
          </b-col>
        </b-row>
        <form @submit.prevent="createNewOrder">
          <b-row>
            <b-col cols="12">
              <div
                class="place-order__form__field"
                :class="
                  form.customerName !== ''
                    ? 'place-order__form__field--filled'
                    : ''
                "
              >
                <label for="customer-name">Customer Name</label>
                <b-form-input
                  class="place-order__form__input"
                  v-model="form.customerName"
                  @focus="setFocus"
                  @focusout="setFocusOut"
                  required
                ></b-form-input>
              </div>
            </b-col>
          </b-row>
          <verify-address
            @set-valid="setAddressValidity"
            @busy="setBusy(true)"
            @active="setBusy(false)"
            :branchId="selectedBranch"
            :parentBusy="processing"
          />
          <b-row>
            <b-col cols="12">
              <div
                class="place-order__form__field"
                :class="
                  form.phone !== '' ? 'place-order__form__field--filled' : ''
                "
              >
                <label for="customer-name">Telephone* (03xxxxxxxx)</label>
                <b-form-input
                  class="place-order__form__input"
                  maxLength="11"
                  v-model="form.phone"
                  @focus="setFocus"
                  @focusout="setFocusOut"
                  required
                ></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row
            ><b-col cols="12">
              <b-form-group label="Payment Type: ">
                <b-form-radio-group
                  id="radio-group-3"
                  v-model="paymentMethod"
                  name="radio-sub-component"
                >
                  <b-form-radio value="cashOnDelivery">Cash</b-form-radio>
                  <b-form-radio value="paidOnline">Online</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col></b-row
          >

          <b-row v-if="paymentMethod === 'cashOnDelivery'">
            <b-col cols="12">
              <div
                class="place-order__form__field"
                :class="
                  form.orderAmount !== ''
                    ? 'place-order__form__field--filled'
                    : ''
                "
              >
                <label for="customer-name">Order Amount</label>
                <b-form-input
                  class="place-order__form__input"
                  v-model="form.orderAmount"
                  @focus="setFocus"
                  @focusout="setFocusOut"
                  type="number"
                  min="1"
                  required
                ></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="text-center">
              <p class="text-danger">{{ orderError }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="d-flex justify-content-center"
              ><button
                type="submit"
                class="baskyt-btn"
                :class="
                  `${
                    processing || busy || !addressValid || !canPlaceOrder
                      ? 'baskyt-btn--disabled'
                      : ''
                  }`
                "
                :disabled="
                  processing || busy || !addressValid || !canPlaceOrder
                "
              >
                Place Order
              </button></b-col
            >
          </b-row>
        </form>
      </div>
    </template>
    <template v-else-if="placeNewOrder === 2" && !processing>
      <baskyt-progress>
        <template v-slot:text>
          Finding you the nearest Baskytor
        </template>
      </baskyt-progress>
    </template>
    <transition name="slide-pop-down">
      <template v-if="placeNewOrder === 3 && orderPlaced !== null">
        <OrderPlaced :order="orderPlaced.order" :rider="orderPlaced.rider" />
      </template>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import OrderPlaced from "@/view/sub-pages/orders/OrderPlaced";
import ApiService from "@/core/services/api.service";
import { getStoreId } from "@/core/services/jwt.service";
import {
  GET_ORDER_STATUS_ACTIVE,
  GET_BRANCHES
} from "@/core/services/store/orders.module";
import VerifyAddress from "@/view/sub-pages/orders/VerifyAddress";
import DateService from "@/core/services/date.service";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}place-order`,
  components: {
    OrderPlaced,
    "verify-address": VerifyAddress
  },
  data() {
    return {
      placeNewOrder: 0,
      notification: false,
      form: {
        customerName: "",
        phone: "03",
        orderAmount: ""
      },
      toCustomer: null,
      processing: false,
      orderError: "",
      orderPlaced: null,
      busy: false,
      addressValid: false,
      address: null,
      orderCharges: null,
      verifyAttempt: false,
      canPlaceOrder: true,
      paymentMethod: "cashOnDelivery",
      payload: "Order Placed",
      color: "",
      branches: null,
      nBranches: 0,
      preOrderError: null,
      selectedBranch: null,
      branchesDisabled: false
    };
  },
  async mounted() {
    const res = DateService.canPlaceOrder();
    await this.$store.dispatch(GET_BRANCHES).then(branchesResponse => {
      if (branchesResponse === null) {
        this.preOrderError = "Fatal Error: No store branches could be found.";
      } else {
        const { branches } = branchesResponse;
        this.nBranches = branches.nBranches;
        this.branches = branches.branches.map(branch => ({
          ...branch,
          html: `<i class="fa fa-map-marker">${branch.address.address}</i>`
        }));
        this.selectedBranch = branches.branches[0].id; // this.branches[0].address.address//.id;
        if (this.nBranches === 1) {
          this.branchesDisabled = false;
        }
      }
      if (res === false) {
        this.canPlaceOrder = false;
      }
    });
  },

  methods: {
    ...mapGetters(["isFetchingActive", "getPushToken"]),
    setBusy(state) {
      this.busy = state;
    },
    backToStep1() {
      this.placeNewOrder = 0;
    },
    placeOrder() {
      if (this.selectedBranch === null) {
        this.preOrderError = "No Branch selected.";
        return;
      }
      this.placeNewOrder = 1;
    },
    setAddressValidity(val) {
      this.addressValid = val.state;
      this.address = val.shippingAddress;
      this.orderCharges = val.orderCharges;
      this.toCustomer = val.toCustomer;
      this.verifyAttempt = true;
    },
    async createNewOrder() {
      const client = getStoreId();
      this.orderError = "";
      const { customerName, phone, orderAmount: amount } = this.form;
      if (this.verifyAttempt && !this.addressValid) {
        this.orderError = !this.addressValid
          ? "No Valid Address Found to deliver the order."
          : "";
      }
      const { address, orderCharges, toCustomer, paymentMethod } = this;
      if (orderCharges === null || address === null || toCustomer === null) {
        return;
      }
      const order = {
        client,
        customerName,
        phone,
        amount: paymentMethod === "cashOnDelivery" ? amount : 0,
        address,
        orderCharges,
        toCustomer,
        paymentMethod,
        branchId: this.selectedBranch
      };
      this.processing = true;
      const { data: response } = await ApiService.post(
        "/store/placeBusinessOrder",
        order
      );
      if (response.success === 1) {
        this.processing = false;
        this.placeNewOrder = 2;
        this.orderPlaced = response.order;
        this.orderCreated(response.order);
      } else {
        this.orderError = response.error.message;
        this.processing = false;
      }
    },
    async orderCreated(data) {
      if (!this.isFetchingActive()) {
        if (!this.$store.getters.getActiveOrder(data.orderId))
          await this.$store.dispatch(GET_ORDER_STATUS_ACTIVE);
        this.$router.push(`/order/${data.orderId}`);
      } else {
        setTimeout(() => {
          this.orderCreated(data);
        }, 2000);
      }
    },
    setFocus(e) {
      const { target } = e;
      const parent = target.closest(".place-order__form__field");
      const classes = parent.classList;
      if (!classes.contains("place-order__form__field--focus"))
        parent.classList.add("place-order__form__field--focus");
    },
    setFocusOut(e) {
      const { target } = e;
      const parent = target.closest(".place-order__form__field");
      const classes = parent.classList;
      if (classes.contains("place-order__form__field--focus"))
        parent.classList.remove("place-order__form__field--focus");
    }
  }
};
</script>
<style lang="scss" scoped></style>
