<template>
  <div class="order-placed px-5">
    <template v-if="rider !== null">
      <div class="order-placed__baskytor">
        <div class="order-placed__baskytor__image">
          <img :src="rider.profileImage" alt="user image" />
        </div>
        <div class="order-placed__baskytor__name">
          {{ rider.name }}
        </div>
        <div class="order-placed__baskytor__ratings overlay-menu__ratings">
          <div
            class="overlay-menu__ratings__stars overlay-menu__ratings__stars--silver"
          >
            <i
              v-for="index in 5"
              class="fa fa-star"
              v-bind:class="getGoldClass(rider.avgCustomerRating, index)"
              :key="index"
            ></i>
          </div>
        </div>
        <div class="order-placed__baskytor__rating-text">
          {{ rider.avgCustomerRating }}.0
        </div>
      </div>
    </template>
    <div class="order-placed__status">
      <!-- <div class="os1__customer">
        <div class="os1__customer__name">
          <b>Customer: </b>{{ order.receiver.name }}
        </div>
        <div class="os1__customer__order-number" v-if="order.orderId">
          <b>Order Id:</b> {{ order.orderId }}
        </div>
      </div> -->
      <div class="order-placed__status__points p-5">
        <div
          class="order-placed__status__point"
          :class="
            getStatus(order.status) === 1
              ? 'order-placed__status__point--active'
              : ''
          "
        >
          <img
            :src="
              getImgUrl(
                getStatus(order.status) > 0
                  ? 'rider-icon-colored'
                  : 'rider-icon-silver'
              )
            "
            alt="Rider Icon Colored"
          />
        </div>

        <div
          class="order-placed__status__point"
          :class="
            getStatus(order.status) === 2
              ? 'order-placed__status__point--active'
              : ''
          "
        >
          <img
            src="@/assets/static-assets/app-icons/vendor-icon-silver.png"
            alt="Rider Icon silver"
          />
        </div>
        <div
          class="order-placed__status__point"
          :class="
            getStatus(order.status) === 3
              ? 'order-placed__status__point--active'
              : ''
          "
        >
          <img
            src="@/assets/static-assets/app-icons/container-icon-silver.png"
            alt="Rider Icon silver"
          />
        </div>
        <div
          class="order-placed__status__point"
          :class="
            getStatus(order.status) === 4
              ? 'order-placed__status__point--active'
              : ''
          "
        >
          <img
            src="@/assets/static-assets/app-icons/rider-icon-silver.png"
            alt="Rider Icon silver"
          />
        </div>
        <div
          class="order-placed__status__point"
          :class="
            getStatus(order.status) === 5
              ? 'order-placed__status__point--active'
              : ''
          "
        >
          <img
            src="@/assets/static-assets/app-icons/delivered-icon-silver.png"
            alt="Rider Icon silver"
          />
        </div>
      </div>
      <div class="order-placed__status__info-text">
        {{ order.types.status }}
      </div>
      <div class="order-placed__status__time">
        {{ getEstimatedTime() }}
      </div>
    </div>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-center">
        <router-link
          to="/order-status/in-progress"
          tag="button"
          class="baskyt-btn"
          >Go to Order Status</router-link
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}order-placed`,
  props: {
    order: Object,
    rider: Object
  },

  methods: {
    getImgUrl(image) {
      const images = require.context(
        "@/assets/static-assets/app-icons/",
        false,
        /\.png$/
      );
      return images(`./${image}.png`);
    },
    getGoldClass(total, key) {
      return key <= total ? "gold" : "";
    },
    getStatus() {
      const { status: statusText } = this.order.types;
      let status = 0;
      switch (statusText) {
        case "Pending":
          status = 0;
          break;
        case "Confirmed":
          status = 1;
          break;
        case "Assembling":
          status = 2;
          break;
        case "Picked Up":
          status = 3;
          break;
        case "Reached Customer":
          status = 4;
          break;
        case "Delivered":
          status = 5;
          break;
        case "Cancelled":
          status = 6;
          break;
        default:
          status = 0;
      }
      return status;
    },
    getEstimatedTime() {
      const { status, time, rider } = this.order;
      if (rider === null) {
        return "Looking for Baskytor";
      }
      if (rider !== null && status === "Pending") {
        return "Waiting for Baskytor to accept the order";
      }
      return `Est time: ${time.timeToDeliver}`;
    }
  }
};
</script>

<style lang="scss" scoped></style>
