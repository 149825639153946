<template>
  <div class="verify-address">
    <form @submit.prevent="validateAddress">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Address Type">
            <b-form-radio-group
              id="radio-group-2"
              v-model="form.addressType"
              name="radio-sub-component"
            >
              <template v-for="option in addressTypeOptions">
                <b-form-radio
                  @change="changeAddress"
                  :value="option.value"
                  :key="option.value"
                  >{{ option.text }}</b-form-radio
                >
              </template>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-if="form.addressType === 'Others'">
        <b-row>
          <b-col cols="12">
            <div class="place-order__form__field">
              <google-search @selected="selectedGoogleAddress" />
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col cols="12" md="6" v-if="form.addressType === 'Residential'">
            <div
              class="place-order__form__field"
              :class="
                form.houseNumber !== ''
                  ? 'place-order__form__field--filled'
                  : ''
              "
            >
              <label for="customer-name">House No.*</label>
              <b-form-input
                class="place-order__form__input"
                v-debounce="changeText"
                v-model="form.houseNumber"
                @focus="setFocus"
                @focusout="setFocusOut"
                required
                type="text"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6" v-if="form.addressType === 'Office'">
            <div
              class="place-order__form__field"
              :class="
                form.officeNumber !== ''
                  ? 'place-order__form__field--filled'
                  : ''
              "
            >
              <label for="customer-name">Office No.*</label>
              <b-form-input
                class="place-order__form__input"
                v-model="form.officeNumber"
                v-debounce="changeText"
                @focus="setFocus"
                @focusout="setFocusOut"
                required
                type="number"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6" v-if="form.addressType === 'Apartment'">
            <div
              class="place-order__form__field"
              :class="
                form.flatNumber !== '' ? 'place-order__form__field--filled' : ''
              "
            >
              <label for="customer-name">Flat No.*</label>
              <b-form-input
                class="place-order__form__input"
                v-model="form.flatNumber"
                v-debounce="changeText"
                @focus="setFocus"
                @focusout="setFocusOut"
                type="number"
                required
              ></b-form-input>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div
              class="place-order__form__field"
              :class="
                form.streetNumber !== ''
                  ? 'place-order__form__field--filled'
                  : ''
              "
            >
              <label for="customer-name"
                >Street No.{{
                  form.addressType === "Residential" ? "*" : ""
                }}</label
              >
              <b-form-input
                class="place-order__form__input"
                v-model="form.streetNumber"
                v-debounce="changeText"
                @focus="setFocus"
                @focusout="setFocusOut"
                type="text"
                :required="form.addressType === 'Residential'"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6" v-if="form.addressType === 'Office'">
            <div
              class="place-order__form__field"
              :class="
                form.officePlaza !== ''
                  ? 'place-order__form__field--filled'
                  : ''
              "
            >
              <label for="customer-name">Office Plaza*</label>
              <b-form-input
                class="place-order__form__input"
                v-model="form.officePlaza"
                v-debounce="changeText"
                @focus="setFocus"
                @focusout="setFocusOut"
                required
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6" v-if="form.addressType === 'Office'">
            <div
              class="place-order__form__field"
              :class="
                form.officeFloor !== ''
                  ? 'place-order__form__field--filled'
                  : ''
              "
            >
              <label for="customer-name">Office Floor</label>
              <b-form-select
                class="mt-2"
                v-model="form.officeFloor"
                v-debounce="changeText"
                :options="floorOptions"
              ></b-form-select>
            </div>
          </b-col>
          <b-col cols="12" v-if="form.addressType === 'Apartment'">
            <div
              class="place-order__form__field"
              :class="
                form.apartmentName !== ''
                  ? 'place-order__form__field--filled'
                  : ''
              "
            >
              <label for="customer-name">Apartment Name*</label>
              <b-form-input
                class="place-order__form__input"
                v-model="form.apartmentName"
                v-debounce="changeText"
                @focus="setFocus"
                @focusout="setFocusOut"
                required
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12">
            <div
              class="place-order__form__field"
              :class="
                form.sector !== '' ? 'place-order__form__field--filled' : ''
              "
            >
              <label for="customer-name">Sector* (X-1/2-3)</label>
              <b-form-input
                class="place-order__form__input"
                v-model="form.sector"
                v-debounce="changeText"
                @focus="setFocus"
                @focusout="setFocusOut"
                required
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mx-0 mb-2">
        <b-col
          cols="12"
          class="bg--secondary mx-0 px-2 pb-2"
          style="border-radius:10px;"
        >
          <div
            class="verify-address__progress"
            :class="processing ? 'verify-address__progress--active' : ''"
          ></div>
          <small v-html="addressText || 'No Address'"> </small>
          <small v-html="zoneCharges"> </small>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { getStoreId } from "@/core/services/jwt.service";
import PlacesAutocomplete from "@/view/sub-pages/orders/PlacesAutocomplete";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}verify-address`,
  components: {
    "google-search": PlacesAutocomplete
  },
  props: {
    parentBusy: Boolean,
    branchId: String
  },
  data() {
    return {
      addressTypeOptions: [
        { value: "Residential", text: "Residential" },
        { text: "Others (Offices, Apartments, etc.)", value: "Others" }
      ],
      form: {
        addressType: "Residential",
        houseNumber: "",
        streetNumber: "",
        officeNumber: "",
        flatNumber: "",
        apartmentName: "",
        officePlaza: "",
        officeFloor: "Ground Floor"
      },
      googleAddress: null,
      floorOptions: ["Ground Floor"],
      processing: false,
      validated: false,
      addressText: "",
      zoneCharges: "",
      typing: false
    };
  },
  methods: {
    changeText() {
      this.typing = true;
      this.validateAddress();
    },
    selectedGoogleAddress({ address }) {
      this.googleAddress = address;
      this.validateAddress();
    },

    async validateAddress() {
      this.addressText = "";
      this.zoneCharges = "";
      const {
        houseNumber: house = 0,
        streetNumber: street = 0,
        officeNumber: office = "nil",
        flatNumber: flat = "nil",
        apartmentName: apartment = "nil",
        officePlaza: plaza = "nil",
        officeFloor: floor = "nil",
        sector = null,
        addressType
      } = this.form;
      const order = {
        address: {}
      };
      let streetText = "";
      let flatText = "";
      let houseText = "";
      switch (addressType) {
        case "Others":
          if (this.googleAddress === null) return;
          order.address.text = this.googleAddress.address;
          order.address.coordinates = this.googleAddress.coordinates;

          break;
        case "Residential":
          order.address = {
            house,
            street,
            sector
          };
          if (house === "" || street === "") return;
          if (sector === null || sector.length < 3) return;

          streetText = `Street ${street}, `;
          houseText = `House ${house}, `;
          order.address.street = streetText;
          order.address.house = houseText;
          order.address.text = `${streetText}${houseText}${sector}`;
          break;
        case "Office":
          order.address = {
            office,
            plaza,
            sector,
            street
          };
          if (office === "nill" || plaza === "nil") return;
          if (sector === null || sector.length < 3) return;
          streetText = "";
          if (street !== 0 && street !== "") {
            streetText = `Street ${parseInt(street, 10)}, `;
          }
          if (streetText === "") {
            delete order.address.street;
          } else {
            order.address.street = streetText;
          }
          order.address.text = `${streetText}, Office ${office}, ${floor}, ${plaza} ,${sector}`;
          break;
        case "Apartment":
          order.address = {
            apartment,
            flat,
            sector,
            street
          };
          if (flat === "nill" || apartment === "nil") return;
          if (sector === null || sector.length < 3) return;
          flatText = "";
          streetText = "";
          if (street !== 0 && street !== "") {
            streetText = `Street ${parseInt(street, 10)}, `;
          }
          if (streetText === "") {
            delete order.address.street;
          } else {
            order.address.street = streetText;
          }
          if (flat !== 0 && flat !== "") {
            flatText = `Flat ${parseInt(flat, 10)}, `;
          }
          order.address.text = `${streetText}${flatText}Apartment ${apartment}, ${sector}`;
          break;
        default:
          order.address = {
            house,
            street,
            sector
          };
      }
      if (this.form.addressType !== "Others") {
        order.address.text += ", Islamabad, Pakistan";
      }
      order.address.addressType = this.form.addressType;

      order.client = getStoreId();
      this.processing = true;
      order.branchId = this.branchId;
      this.$emit("busy");

      const { data: response } = await ApiService.post(
        "/store/validateDeliveryAddress",
        {
          ...order
        }
      );
      this.$emit("active");
      this.processing = false;
      if (response.success) {
        order.address.text = response.shippingAddress.address;

        const x = `<b>Address:</b> ${response.shippingAddress.addressText}.`;
        this.addressText = x;
        let zoneText = `<br/><span class="badge badge--baskyt badge--${response.orderCharges.zone.toLowerCase()}">${
          response.orderCharges.zone
        }</span>`;
        zoneText += ` <span class="badge badge--baskyt badge--green">${response.orderCharges.deliveryCharges} PKR</span>`;
        this.zoneCharges = zoneText;
        order.shippingAddress = response.shippingAddress;
        order.orderCharges = response.orderCharges;
        // eslint-disable-next-line prefer-destructuring
        order.toCustomer = response.toCustomer[0];
        this.$emit("set-valid", { state: true, ...order });
      } else {
        order.address.text = response.error.message;
        this.zoneCharges = "";
        this.addressText = `<b>Error:</b> ${response.error.message}`;
        order.shippingAddress = null;
        order.orderCharges = null;
        order.toCustomer = null;
        order.state = false;
        this.$emit("set-valid", order);
      }
    },
    reset() {
      this.form = {
        addressType: "Residential",
        houseNumber: "",
        streetNumber: "",
        officeNumber: "",
        flatNumber: "",
        apartmentName: "",
        officePlaza: "",
        officeFloor: "Ground Floor"
      };
      this.validated = false;
    },
    changeAddress(val) {
      let ind = 9;
      if (val === "Apartment") {
        ind = 19;
      }
      this.form.floor = "Ground Floor";
      const op = ["Ground Floor"];
      new Array(ind).fill().forEach((it, index) => {
        op.push(`Floor ${index + 1}`);
      });
      this.floorOptions = op;
    },
    setFocus(e) {
      const { target } = e;
      const parent = target.closest(".place-order__form__field");
      const classes = parent.classList;
      if (!classes.contains("place-order__form__field--focus"))
        parent.classList.add("place-order__form__field--focus");
    },
    setFocusOut(e) {
      const { target } = e;
      const parent = target.closest(".place-order__form__field");
      const classes = parent.classList;
      if (classes.contains("place-order__form__field--focus"))
        parent.classList.remove("place-order__form__field--focus");
    }
  }
};
</script>

<style lang="scss" scoped></style>
