<template>
  <div>
    <vue-google-autocomplete
      id="map"
      classname="form-control"
      placeholder="Start typing"
      country="pk"
      :fields="[
        'geometry',
        'address_components',
        'place_id',
        'formatted_address',
        'name'
      ]"
      :types="['address']"
      v-on:placechanged="selectedOption"
    >
    </vue-google-autocomplete>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: {
    "vue-google-autocomplete": VueGoogleAutocomplete
  },
  data() {
    return {
      selected: null,
      address: null,
      options: {
        apiKey: process.env.VUE_APP_API_KEY,
        deepSearch: true,
        cors: false,
        focus: false,
        params: {
          language: "en",
          components: "country:pk"
        }
      }
    };
  },

  methods: {
    selectedOption(place, e) {
      this.selected = e;
      const { latitude: lat, longitude: lng } = place;
      this.address = {
        address: `${e.name}, ${e.formatted_address}`,
        coordinates: [lng, lat]
      };
      this.$emit("selected", {
        address: this.address
      });
    }
  }
};
</script>
