<template>
  <div class="orders-home">
    <div class="orders-home__nav">
      <ul class="mobile-tabs__nav  pendingOrders">
        <li>
          <router-link to="/orders/pending">
            <div
              class="mobile-tabs__title"
              :class="orderActiveTab === 0 ? 'mobile-tabs__title--active' : ''"
            >
              <span class="mobile-tabs__title__count">0</span>
              <span>Pending</span>
            </div></router-link
          >
        </li>
        <li v-show="!isUs">
          <router-link to="/orders/place-new">
            <div
              class="mobile-tabs__title"
              :class="orderActiveTab === 1 ? 'mobile-tabs__title--active' : ''"
            >
              <span>Place Order</span>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="mobile-tabs__indicator-container">
        <template v-if="isUs">
          <div
            class="mobile-tabs__indicator mobile-tabs__indicator--full"
          ></div>
        </template>
        <template v-else>
          <div
            class="mobile-tabs__indicator"
            :class="
              orderActiveTab === 1
                ? 'mobile-tabs__indicator--right'
                : 'mobile-tabs__indicator--left'
            "
          ></div>
        </template>
      </div>
    </div>
    <b-tabs
      class="mobile-tabs"
      nav-class="d-none"
      content-class="border-red"
      v-model="orderActiveTab"
    >
      <b-tab>
        <template v-if="orderActiveTab === 0">
          <OrdersHome />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="orderActiveTab === 1">
          <PlaceOrder />
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PlaceOrder from "@/view/sub-pages/orders/PlaceOrder";
import OrdersHome from "@/view/sub-pages/orders/OrdersHome";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}orders-home`,
  components: {
    OrdersHome,
    PlaceOrder
  },
  data() {
    return {
      orderActiveTab: 0
    };
  },
  watch: {
    $route(to) {
      const { id } = to.params;
      if (id === "pending") {
        this.orderActiveTab = 0;
      } else if (id === "place-new") {
        if (this.isUs) {
          this.$router.history.push("/orders/pending");
          this.orderActiveTab = 0;
        } else {
          this.orderActiveTab = 1;
        }
      } else {
        this.orderActiveTab = 1;
      }
    }
  },
  computed: {
    ...mapState({
      isUs: state => state.regions.region
    })
  }
};
</script>
<style lang="scss" scoped>
.pendingOrders {
  justify-content: center;
}
</style>
