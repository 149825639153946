<template>
  <div class="orders-main">
    <div class="orders-main__part">
      <pending-orders />
    </div>
  </div>
</template>

<script>
import PendingOrders from "@/view/sub-pages/orders/PendingOrders";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}orders-main`,
  components: {
    PendingOrders
  }
};
</script>

<style lang="scss" scoped></style>
