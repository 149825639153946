var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-google-autocomplete',{attrs:{"id":"map","classname":"form-control","placeholder":"Start typing","country":"pk","fields":[
      'geometry',
      'address_components',
      'place_id',
      'formatted_address',
      'name'
    ],"types":['address']},on:{"placechanged":_vm.selectedOption}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }