var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"verify-address"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateAddress.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Address Type"}},[_c('b-form-radio-group',{attrs:{"id":"radio-group-2","name":"radio-sub-component"},model:{value:(_vm.form.addressType),callback:function ($$v) {_vm.$set(_vm.form, "addressType", $$v)},expression:"form.addressType"}},[_vm._l((_vm.addressTypeOptions),function(option){return [_c('b-form-radio',{key:option.value,attrs:{"value":option.value},on:{"change":_vm.changeAddress}},[_vm._v(_vm._s(option.text))])]})],2)],1)],1)],1),(_vm.form.addressType === 'Others')?[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"place-order__form__field"},[_c('google-search',{on:{"selected":_vm.selectedGoogleAddress}})],1)])],1)]:[_c('b-row',[(_vm.form.addressType === 'Residential')?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"place-order__form__field",class:_vm.form.houseNumber !== ''
                ? 'place-order__form__field--filled'
                : ''},[_c('label',{attrs:{"for":"customer-name"}},[_vm._v("House No.*")]),_c('b-form-input',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.changeText),expression:"changeText"}],staticClass:"place-order__form__input",attrs:{"required":"","type":"text"},on:{"focus":_vm.setFocus,"focusout":_vm.setFocusOut},model:{value:(_vm.form.houseNumber),callback:function ($$v) {_vm.$set(_vm.form, "houseNumber", $$v)},expression:"form.houseNumber"}})],1)]):_vm._e(),(_vm.form.addressType === 'Office')?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"place-order__form__field",class:_vm.form.officeNumber !== ''
                ? 'place-order__form__field--filled'
                : ''},[_c('label',{attrs:{"for":"customer-name"}},[_vm._v("Office No.*")]),_c('b-form-input',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.changeText),expression:"changeText"}],staticClass:"place-order__form__input",attrs:{"required":"","type":"number"},on:{"focus":_vm.setFocus,"focusout":_vm.setFocusOut},model:{value:(_vm.form.officeNumber),callback:function ($$v) {_vm.$set(_vm.form, "officeNumber", $$v)},expression:"form.officeNumber"}})],1)]):_vm._e(),(_vm.form.addressType === 'Apartment')?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"place-order__form__field",class:_vm.form.flatNumber !== '' ? 'place-order__form__field--filled' : ''},[_c('label',{attrs:{"for":"customer-name"}},[_vm._v("Flat No.*")]),_c('b-form-input',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.changeText),expression:"changeText"}],staticClass:"place-order__form__input",attrs:{"type":"number","required":""},on:{"focus":_vm.setFocus,"focusout":_vm.setFocusOut},model:{value:(_vm.form.flatNumber),callback:function ($$v) {_vm.$set(_vm.form, "flatNumber", $$v)},expression:"form.flatNumber"}})],1)]):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"place-order__form__field",class:_vm.form.streetNumber !== ''
                ? 'place-order__form__field--filled'
                : ''},[_c('label',{attrs:{"for":"customer-name"}},[_vm._v("Street No."+_vm._s(_vm.form.addressType === "Residential" ? "*" : ""))]),_c('b-form-input',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.changeText),expression:"changeText"}],staticClass:"place-order__form__input",attrs:{"type":"text","required":_vm.form.addressType === 'Residential'},on:{"focus":_vm.setFocus,"focusout":_vm.setFocusOut},model:{value:(_vm.form.streetNumber),callback:function ($$v) {_vm.$set(_vm.form, "streetNumber", $$v)},expression:"form.streetNumber"}})],1)]),(_vm.form.addressType === 'Office')?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"place-order__form__field",class:_vm.form.officePlaza !== ''
                ? 'place-order__form__field--filled'
                : ''},[_c('label',{attrs:{"for":"customer-name"}},[_vm._v("Office Plaza*")]),_c('b-form-input',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.changeText),expression:"changeText"}],staticClass:"place-order__form__input",attrs:{"required":""},on:{"focus":_vm.setFocus,"focusout":_vm.setFocusOut},model:{value:(_vm.form.officePlaza),callback:function ($$v) {_vm.$set(_vm.form, "officePlaza", $$v)},expression:"form.officePlaza"}})],1)]):_vm._e(),(_vm.form.addressType === 'Office')?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"place-order__form__field",class:_vm.form.officeFloor !== ''
                ? 'place-order__form__field--filled'
                : ''},[_c('label',{attrs:{"for":"customer-name"}},[_vm._v("Office Floor")]),_c('b-form-select',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.changeText),expression:"changeText"}],staticClass:"mt-2",attrs:{"options":_vm.floorOptions},model:{value:(_vm.form.officeFloor),callback:function ($$v) {_vm.$set(_vm.form, "officeFloor", $$v)},expression:"form.officeFloor"}})],1)]):_vm._e(),(_vm.form.addressType === 'Apartment')?_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"place-order__form__field",class:_vm.form.apartmentName !== ''
                ? 'place-order__form__field--filled'
                : ''},[_c('label',{attrs:{"for":"customer-name"}},[_vm._v("Apartment Name*")]),_c('b-form-input',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.changeText),expression:"changeText"}],staticClass:"place-order__form__input",attrs:{"required":""},on:{"focus":_vm.setFocus,"focusout":_vm.setFocusOut},model:{value:(_vm.form.apartmentName),callback:function ($$v) {_vm.$set(_vm.form, "apartmentName", $$v)},expression:"form.apartmentName"}})],1)]):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"place-order__form__field",class:_vm.form.sector !== '' ? 'place-order__form__field--filled' : ''},[_c('label',{attrs:{"for":"customer-name"}},[_vm._v("Sector* (X-1/2-3)")]),_c('b-form-input',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.changeText),expression:"changeText"}],staticClass:"place-order__form__input",attrs:{"required":""},on:{"focus":_vm.setFocus,"focusout":_vm.setFocusOut},model:{value:(_vm.form.sector),callback:function ($$v) {_vm.$set(_vm.form, "sector", $$v)},expression:"form.sector"}})],1)])],1)],_c('b-row',{staticClass:"mx-0 mb-2"},[_c('b-col',{staticClass:"bg--secondary mx-0 px-2 pb-2",staticStyle:{"border-radius":"10px"},attrs:{"cols":"12"}},[_c('div',{staticClass:"verify-address__progress",class:_vm.processing ? 'verify-address__progress--active' : ''}),_c('small',{domProps:{"innerHTML":_vm._s(_vm.addressText || 'No Address')}}),_c('small',{domProps:{"innerHTML":_vm._s(_vm.zoneCharges)}})])],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }