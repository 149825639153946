<template>
  <div class="pending-orders">
    <div
      class="loader-spinner__container"
      :style="`opacity:${waitingPending ? '1' : '0'}`"
    >
      <img
        class="loader-spinner loader-spinner--sm loader-spinner--left20"
        src="@/assets/static-assets/app-icons/spinner.gif"
        alt="Spinner"
      />
    </div>
    <!-- <div class="pending-orders__title">Pending Orders</div> -->
    <ul class="pending-orders__list row">
      <template v-if="orders.length === 0 && squads.length === 0">
        <div class="pending-orders__no-orders">Oops! No Orders Yet.</div>
      </template>
      <template v-else>
        <template v-for="order in orders">
          <li class="col-12 col-lg-6 " :key="order.id">
            <div class="pending-orders__list__item px-5">
              <div class="pending-orders__details">
                <div class="pending-orders__details__number pb-2">
                  Order Id: {{ order.orderId }}
                </div>
                <div class="pending-orders__details__products py-1">
                  No. of Products:
                  {{ order.noOfProducts }}
                </div>
                <div
                  v-if="isUs === false"
                  class="pending-orders__details__del-time py-1"
                >
                  Exp. Delivery Time:
                  {{ order.time.timeToDeliver }} mins
                </div>
                <div v-else class="pending-orders__details__del-time py-1">
                  Exp. Delivery Time:
                  {{ humanize(order.stores[0].expectedTime) }}
                </div>
                <div v-if="isUs" class="pending-orders__details__amount py-1">
                  Order Amount: ${{ order.estimatedAmount }}
                </div>
                <div v-else class="pending-orders__details__amount py-2">
                  Order Amount: PKR
                  {{ order.amount.total }}
                </div>
              </div>
              <div class="pending-orders__action">
                <button
                  @click="acceptOrder(order.orderId)"
                  class="pending-orders__btn pending-orders__btn--accept"
                  :disabled="waitingPending || waiting"
                >
                  Accept
                </button>
              </div>
            </div>
          </li>
        </template>
      </template>
      <template v-if="squads.length > 0">
        <template v-for="squad in squads">
          <li class="col-12 col-lg-6 " :key="squad.id">
            <div class="pending-orders__list__item px-5">
              <div class="pending-orders__details">
                <div class="pending-orders__details__number pb-2">
                  Squad Id: {{ squad.inviteId }}
                </div>
                <div class="pending-orders__details__products py-1">
                  No. of Products:
                  {{
                    squad.deliveryInfo.reduce((acc, it) => it.quantity + acc, 0)
                  }}
                </div>
                <div class="pending-orders__details__del-time py-1">
                  Exp. Delivery Time:
                </div>

                <div class="pending-orders__details__amount py-1">
                  Order Amount: {{ isUs ? "$" : "PKR" }}
                </div>
              </div>
              <div class="pending-orders__action">
                <button
                  @click="acceptOrder(squad._id, squad.inviteId)"
                  class="pending-orders__btn pending-orders__btn--accept"
                  :disabled="waitingPending || waiting"
                >
                  Accept
                </button>
              </div>
            </div>
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import { ACCEPT_ORDER } from "@/core/services/store/orders.module";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}pending-orders`,
  data() {
    return {
      waiting: false,
      progressWidth: 0,
      orderData: [],
      form: {
        orderId: "",
        noOfProducts: "",
        expDeliveryTime: "",
        orderAmount: ""
      }
    };
  },
  methods: {
    ...mapGetters([
      {
        getPendingOrders: "getPendingOrders",
        getOrders: "getPendingOrderStatus"
      }
    ]),
    async acceptOrder(id, squad = false) {
      this.fetchingOrder = false;
      this.waiting = true;
      await this.$store.dispatch(ACCEPT_ORDER, { id, squad: !!squad });
      this.waiting = false;
      this.$router.push({
        path: `/order/${squad !== false ? squad : id}`
      });
    },
    humanize(date) {
      const humanDate = moment(date).format("MMMM Do YYYY");
      return humanDate;
    }
  },
  computed: {
    ...mapState({
      waitingPending: state => state.orders.fetchingPending,
      orders: state => {
        const { region: isUS } = state.regions;
        const { orderStatusPending: tempOrders } = state.orders;
        return tempOrders.map(order => {
          if (!isUS) {
            return { ...order, noOfProducts: order.stores[0].products.length };
          }
          return {
            ...order,
            noOfProducts: order.products.length
          };
        });
      },
      squads: ({ orders }) => {
        const { squadStatusPending: tempSquads } = orders;
        return tempSquads;
      },
      isUs: state => state.regions.region
    })
  }
};
</script>
<style lang="scss" scoped></style>
