var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-placed px-5"},[(_vm.rider !== null)?[_c('div',{staticClass:"order-placed__baskytor"},[_c('div',{staticClass:"order-placed__baskytor__image"},[_c('img',{attrs:{"src":_vm.rider.profileImage,"alt":"user image"}})]),_c('div',{staticClass:"order-placed__baskytor__name"},[_vm._v(" "+_vm._s(_vm.rider.name)+" ")]),_c('div',{staticClass:"order-placed__baskytor__ratings overlay-menu__ratings"},[_c('div',{staticClass:"overlay-menu__ratings__stars overlay-menu__ratings__stars--silver"},_vm._l((5),function(index){return _c('i',{key:index,staticClass:"fa fa-star",class:_vm.getGoldClass(_vm.rider.avgCustomerRating, index)})}),0)]),_c('div',{staticClass:"order-placed__baskytor__rating-text"},[_vm._v(" "+_vm._s(_vm.rider.avgCustomerRating)+".0 ")])])]:_vm._e(),_c('div',{staticClass:"order-placed__status"},[_c('div',{staticClass:"order-placed__status__points p-5"},[_c('div',{staticClass:"order-placed__status__point",class:_vm.getStatus(_vm.order.status) === 1
            ? 'order-placed__status__point--active'
            : ''},[_c('img',{attrs:{"src":_vm.getImgUrl(
              _vm.getStatus(_vm.order.status) > 0
                ? 'rider-icon-colored'
                : 'rider-icon-silver'
            ),"alt":"Rider Icon Colored"}})]),_c('div',{staticClass:"order-placed__status__point",class:_vm.getStatus(_vm.order.status) === 2
            ? 'order-placed__status__point--active'
            : ''},[_c('img',{attrs:{"src":require("@/assets/static-assets/app-icons/vendor-icon-silver.png"),"alt":"Rider Icon silver"}})]),_c('div',{staticClass:"order-placed__status__point",class:_vm.getStatus(_vm.order.status) === 3
            ? 'order-placed__status__point--active'
            : ''},[_c('img',{attrs:{"src":require("@/assets/static-assets/app-icons/container-icon-silver.png"),"alt":"Rider Icon silver"}})]),_c('div',{staticClass:"order-placed__status__point",class:_vm.getStatus(_vm.order.status) === 4
            ? 'order-placed__status__point--active'
            : ''},[_c('img',{attrs:{"src":require("@/assets/static-assets/app-icons/rider-icon-silver.png"),"alt":"Rider Icon silver"}})]),_c('div',{staticClass:"order-placed__status__point",class:_vm.getStatus(_vm.order.status) === 5
            ? 'order-placed__status__point--active'
            : ''},[_c('img',{attrs:{"src":require("@/assets/static-assets/app-icons/delivered-icon-silver.png"),"alt":"Rider Icon silver"}})])]),_c('div',{staticClass:"order-placed__status__info-text"},[_vm._v(" "+_vm._s(_vm.order.types.status)+" ")]),_c('div',{staticClass:"order-placed__status__time"},[_vm._v(" "+_vm._s(_vm.getEstimatedTime())+" ")])]),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('router-link',{staticClass:"baskyt-btn",attrs:{"to":"/order-status/in-progress","tag":"button"}},[_vm._v("Go to Order Status")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }